import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'
import '../styles/pages/_about.scss'

const AboutPage = ({ data: { datoCmsAboutPage: about } }) => (
  <Layout>
    <HelmetDatoCms seo={about.seoMetaTags} />
    <section
      className="hero is-large is-page-hero"
      style={{ backgroundImage: `url(${about.heroBackgroundImage.fixed.src})` }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal">
            about us
          </h1>
        </div>
      </div>
    </section>
    <section className="hero is-secondary-hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-2">
            {ReactHtmlParser(about.straplineNode.childMarkdownRemark.html)}
          </h1>
        </div>
      </div>
    </section>
    <section
      className="hero is-third-hero has-hero-background"
      style={{ backgroundImage: `url(${about.secondHeroImage.fixed.src})` }}
    >
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1 has-text-white has-text-right">
            {about.secondHeroText}
          </h1>
        </div>
      </div>
    </section>
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-3">our story</h1>
        </div>
      </div>
    </section>
    {about.ourStory.map(story => (
      <div key={story.id} className="columns is-centered is-story-block">
        <div
          className="column is-half has-hero-background is-story-image"
          style={{ backgroundImage: `url(${story.storyImage.fixed.src})` }}
        />
        <div className="column is-story-content">
          <div className="content">
            {ReactHtmlParser(story.storyTextNode.childMarkdownRemark.html)}
          </div>
        </div>
      </div>
    ))}
    <section className="section is-our-story-image">
      <figure className="image is-3by1">
        <Img fluid={about.ourStoryImage.fluid} alt="Our Story Image" />
      </figure>
    </section>
    <section className="hero" style={{ backgroundColor: '#A5D5D0' }}>
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-3 has-text-white">our values</h1>
        </div>
      </div>
    </section>
    <div className="columns is-multiline" style={{ backgroundColor: 'black' }}>
      {about.ourValues.map(value => (
        <div
          key={value.id}
          className="column is-half is-values-block has-hero-background"
          style={{ backgroundImage: `url(${value.backgroundImage.fixed.src})` }}
        >
          <section className="hero">
            <div className="hero-body">
              <h1 className="title is-size-4 has-text-white">{value.title}</h1>
              <div className="content has-text-white">
                {ReactHtmlParser(value.textNode.childMarkdownRemark.html)}
              </div>
            </div>
          </section>
        </div>
      ))}
    </div>
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const query = graphql`
  query AboutQuery {
    datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
          }
        ) {
          src
        }
      }
      straplineNode {
        childMarkdownRemark {
          html
        }
      }
      secondHeroText
      secondHeroImage {
        fixed(width: 1920, imgixParams: { auto: "compress", fm: "pjpg" }) {
          src
        }
      }
      ourStory {
        ... on DatoCmsStoryBlock {
          id
          storyTextNode {
            childMarkdownRemark {
              html
            }
          }
          storyImage {
            fixed(
              width: 800
              imgixParams: {
                h: "600"
                auto: "compress"
                fm: "pjpg"
                fit: "fill"
                crop: "entropy"
                bri: -10
              }
            ) {
              src
            }
          }
        }
      }
      ourStoryImage {
        fluid(
          maxWidth: 1080
          maxHeight: 400
          imgixParams: { auto: "compress", fm: "pjpg", bri: -10 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      ourValues {
        ... on DatoCmsValuesBlock {
          id
          title
          textNode {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            fixed(
              width: 800
              imgixParams: {
                h: "600"
                auto: "compress"
                fm: "pjpg"
                fit: "fill"
                crop: "entropy"
                monochrome: "504F4F"
                bri: -10
              }
            ) {
              src
            }
          }
        }
      }
    }
  }
`
